import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import styled from "../styling/styled";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 100vh;
`;

class NotFoundPage extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.404.title1" }) + " | Eledio.com"
          }
          meta={[
            {
              name: "description",
              content: intl.formatMessage({ id: "pages.404.text1" })
            },
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.404.title1" })
            },
            {
              property: "og:description",
              content: intl.formatMessage({ id: "pages.404.text1" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Wrapper>
          <Container>
            <h1>
              <FormattedMessage id="pages.404.title1" />
            </h1>
            <p>
              <FormattedMessage id="pages.404.text1" />
            </p>
            <Link to="/" title="Back to homepage">
              <FormattedMessage id="pages.404.link" />
            </Link>
          </Container>
        </Wrapper>
      </>
    );
  }
}

export default injectIntl(NotFoundPage);
